import request from '@/utils/request'

// 会员时长排行
export function getMemberRanking(params) {
    return request({
    url: `/v2/api/app/ap_member//member/query/time/leaderboard?appName=${params.appName}`,
    method: 'get',
    })
}

// 我负责的活动
export function getMyActivity(params,memberId) {
    return request({
      url: `/v2/api/app/ad_activity//activity/query_my_leader_activity?page=${params.num}&size=${params.size}&memberId=${memberId}`,
      method: 'get',
    })
}

export default { getMemberRanking, getMyActivity }