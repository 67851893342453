<template>
  <div class="paihangbang outerFrame">
    <div class="topImg">
      <!-- <i class="iconfont icon-fanhui" @click="returnIos"></i> -->
      <img src="@/assets/rankinglist/bg.png" alt />
    </div>
    <div class="rankListBox">
      <!-- <div class="rank_head">
        <div class="my_source">
          <img class="source_img" src="@/assets/rankinglist/mysource.png" alt />
          <img class="myhead_img" src="@/assets/images/sc1.jpg" alt />
          <div class="rank_info">
            <p class="rank_name">张大红</p>
            <p>排名:68</p>
          </div>
        </div>
        <div class="rank_source">
          <span>|</span>
          <span>时长:288</span>
        </div>
      </div> --> 
      <div class="rank_mid">
        <span class="cl1">排名</span>
        <span class="cl2">昵称</span>
        <span class="cl3">获得时长</span>
      </div>
      <div class="rank" v-for="( item,index) in rankList" :key="index">
        <div class="leftContent cl1">
          <img class="paiming_img" v-if="index===0" src="@/assets/rankinglist/one.png" alt />
          <img class="paiming_img" v-else-if="index===1" src="@/assets/rankinglist/two.png" alt />
          <img class="paiming_img" v-else-if="index===2" src="@/assets/rankinglist/three.png" alt />
          <span v-else class="num">{{index+1}}</span>
        </div>
        <div class="cl2">
           <span class="avatar_img" v-if="item.realName">{{item.realName.substring(item.realName.length-2)}}</span>
          <span class="username" v-if="item.realName">{{item.realName}}</span>
        </div>
        <span class="score cl3" >{{item.serverTime}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Memberapi from '@/api/member'

export default {
  data() {
    return {
      type: 2,
      page: 1,
      rows: 20,
      // 非前三人员集合
      rankList: [],
      // 请求数据avatar数据丢失 ,用固定头像
      localAvatar: require('../../assets/images/2.png'),
      params: {
        appName: this.appName
      },
    };
  },
  mounted() {
    // alert(this.params.appName)
    this.getRankiglist();
  },
  filters: {
    chuliPhoto(str) {
      // console.log(str);
      if (str.search(/http/g) == -1) {
        return require('../../assets/images/2.png');
      } else {
        return str;
      }
    }
  },
  methods: {
    returnIos() {
      this.$router.go(-1);
    },
    // 时长排行榜
    getRankiglist: function () {
      Memberapi.getMemberRanking(this.params).then((res) => {
        console.log(res.data);
        if (res.success) {
          this.rankList = res.data;
          // alert(this.rankList);
        }
      }).catch((err) => {
        console.log(err);
      })
      // this.$http
      //   .get(this.javalinkTwo + "usercenter/member/app/query/score/leaderboard", {
      //     params: {
      //       type: this.type,
      //       page: this.page,
      //       rows: this.rows
      //     }
      //   })
      //   .then(resp => {
      //     // console.log(resp);
      //     if(resp.data.success){
      //       this.rankList = resp.data.data;
      //     }
      //   });
    }
  }
};
</script>
<style>
.van-tabs__content {
  overflow: hidden;
}
</style>
<style lang="less" scoped>
.cl1{
 width: 25%;
  justify-content:flex-start
}
.cl2{
  width: 45%;
  text-align: center;
  display: flex;
  align-items: center;
    .avatar_img {
    width: 0.8rem;
    height: 0.8rem;
    // vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
    background: #1377e2;
    text-align: center;
    line-height: 0.8rem;
    color: #fff;
    font-size: 0.25rem;
    object-fit: cover;
  }
}
.cl3{
  width: 30%;
  text-align: center;
}
.paihangbang {
  background: #fff;
}
#app {
  max-width: 7.5rem;
}
.leftContent {
  display: flex;
  align-items: center;
  .paiming_img {
    width: 12vw;
  }
  .avatar_img {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-left: 21vw;
    margin-right: 10px;
    background: #1377e2;
    text-align: center;
    line-height: 1rem;
    color: #fff;
    font-size: 0.3rem;
    object-fit: cover;
  }
}
.topImg {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 2.69rem;
  z-index: 9;
  .icon-fanhui {
    color: #fff;
    position: absolute;
    top: 30px;
    left: 12px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.rankListBox {
  background: #fff;
  border-radius: 20px 20px 0 0;
  margin-top: -20px;
  z-index: 99;
  position: relative;
  .rank_mid {
    display: flex;
    justify-content: space-between;
    padding: 2vw 3vw;
    font-size: 5vw;
    color: #828282;
  }
}
.rank {
  width: 100%;
  height: 1.4rem;
  line-height: 1rem;
  // vertical-align: middle;
  padding: 10px 12px;
  font-size: 0.24rem;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .num {
    color: #4f4f4f;
    display: inline-block;
    width: 12vw;
    text-align: center;
  }
  .username,
  .score {
    font-size: 4.3vw;
  }
}
</style>


